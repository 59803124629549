import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faHeart,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
              <a className="logo-footer" href="#">
                SpacePilot<span className="text-main">.</span>
              </a>
              <p className="mt-4">Get ready for lift off</p>
              {/* <ul className="list-unstyled social-icon social mb-0 mt-4">
                <li className="list-inline-item">
                  <a href="#" className="rounded">
                    <FontAwesomeIcon icon={faBomb} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="rounded">
                    <FontAwesomeIcon icon={faHeart} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="rounded">
                    <FontAwesomeIcon icon={faDumbbell} />
                  </a>
                </li>
              </ul> */}
            </div>

            <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h4 className="text-light footer-head">Company</h4>
              <ul className="list-unstyled footer-list mt-4">
                <li>
                  <a href="/portfolio" className="text-foot">
                    <FontAwesomeIcon icon={faChevronRight} /> Our Work
                  </a>
                </li>
                <li>
                  <a href="/team" className="text-foot">
                    <FontAwesomeIcon icon={faChevronRight} /> Team
                  </a>
                </li>
                <li>
                  <a href="/contactus" className="text-foot">
                    <FontAwesomeIcon icon={faChevronRight} /> Contact Us
                  </a>
                </li>
              </ul>
            </div>

            {/* <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h4 className="text-light footer-head">Usefull Links</h4>
              <ul className="list-unstyled footer-list mt-4">
                <li>
                  <a href="page-terms.html" className="text-foot">
                    <FontAwesomeIcon icon={faChevronRight} /> Terms of Services
                  </a>
                </li>
                <li>
                  <a href="page-privacy.html" className="text-foot">
                    <FontAwesomeIcon icon={faChevronRight} /> Privacy Policy
                  </a>
                </li>
              </ul>
            </div> */}

            <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 text-center">
              <h4 className="text-light footer-head">Chat to a pilot</h4>
              <p className="mt-4">Why wait?</p>
              <ul className="list-unstyled footer-list mt-4">
                <li>
                  <FontAwesomeIcon icon={faEnvelope} /> hello@spacepilot.io
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <footer className="footer footer-bar">
        <div className="container text-center">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="text-sm-left">
                <p className="mb-0">
                  © 2019-20 SpacePilot.
                  <FontAwesomeIcon icon={faHeart} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
