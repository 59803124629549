/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/style.scss"

const Layout = ({ children }) => {
  const [vis, toggleVis] = useState(true)
  useEffect(() => {
    setTimeout(() => toggleVis(false), 1000)
  })
  return (
    <>
      <div id="preloader" style={{ display: `${vis ? "block" : "none"}` }}>
        <div id="status" style={{ display: `${vis ? "block" : "none"}` }}>
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
      <Header />
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
