import React, { useState } from "react"

const Header = () => {
  const [open, toggleOpen] = useState(false)
  const handleToggle = event => toggleOpen(!open);
  return (
    <header id="topnav" className="defaultscroll sticky nav-sticky">
      <div className="container">
        {/* Logo container*/}
        <div>
          <a className="logo" href="/">
            SpacePilot<span className="text-main">.</span>
          </a>
        </div>
        {/* End Logo container*/}
        <div className="menu-extras">
          <div className="menu-item">
            {/* Mobile menu toggle*/}
            <a className={`navbar-toggle ${open ? "open" : ""}`} onClick={handleToggle}>
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            {/* End mobile menu toggle*/}
          </div>
        </div>

        <div id="navigation" style={{display: `${open ? "block" : "none"}`}}>
          {/* Navigation Menu*/}
          <ul className="navigation-menu">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/portfolio">Our Work</a>
            </li>
            <li>
              <a href="/team">Team</a>
            </li>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
          </ul>
          {/*end navigation menu*/}
        </div>
        {/*end navigation*/}
      </div>
      {/*end container*/}
    </header>
  )
}

export default Header
